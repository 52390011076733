import request from '@/utils/request'


// 查询用户购买剧集记录列表
export function listBuyVideo(query) {
  return request({
    url: '/user/user-buy-videos/list',
    method: 'get',
    params: query
  })
}

// 查询用户购买剧集记录分页
export function pageBuyVideo(query) {
  return request({
    url: '/user/user-buy-videos/page',
    method: 'get',
    params: query
  })
}

// 查询用户购买剧集记录详细
export function getBuyVideo(data) {
  return request({
    url: '/user/user-buy-videos/detail',
    method: 'get',
    params: data
  })
}

// 新增用户购买剧集记录
export function addBuyVideo(data) {
  return request({
    url: '/user/user-buy-videos/add',
    method: 'post',
    data: data
  })
}

// 修改用户购买剧集记录
export function updateBuyVideo(data) {
  return request({
    url: '/user/user-buy-videos/edit',
    method: 'post',
    data: data
  })
}

// 删除用户购买剧集记录
export function delBuyVideo(data) {
  return request({
    url: '/user/user-buy-videos/delete',
    method: 'post',
    data: data
  })
}
